/*!
  Theme Name: Foliobox - Portfolio React template
  Author: Obxtheme
  Version: 1.0.0
  Tags: agency, bootstrap, business, creative, gallery, isotope, minimal, modern, personal portfolio, portfolio, responsive
  */

/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Normalize
  # Typography
  # Elements
  # Forms
  # Sections
    ## Header
    ## Dropdown menu
    ## Offcanvus
    ## Portfolio section
    ## Footer section
  # Home page 2
  # Home page 3
  # Portfolio details page
  # About page
  # Responsive CSS


  --------------------------------------------------------------*/

/* Font */

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,900|Titillium+Web:300,400,600,700,900");

/*--------------------------------------------------------------
  ##  Normalize
  --------------------------------------------------------------*/

html {
  direction: rtl;
}

h1 {
  margin: 0;
}

a {
  background-color: transparent;
  text-decoration: none;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

img {
  border-style: none;
  max-width: 100%;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  appearance: button;
}

button:hover {
  border: 0 solid;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0;
}

legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
:focus-visible {
  outline: none;
}

input[type="number"] {
  appearance: textfield;
}

[type="search"] {
  appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

[hidden] {
  display: none;
}

a:focus,
input:focus,
button:focus,
-moz-button:focus {
  outline: none;
}

/*--------------------------------------------------------------
  ##  Typography
  --------------------------------------------------------------*/

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 25px;
  color: #4c455a;
  -moz-osx-font-smoothing: grayscale;
}

span,
a,
button,
input {
  font-family: "Open Sans", sans-serif;
}

button:focus,
input:focus,
select:focus,
optgroup:focus,
textarea:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  font-weight: 700;
  line-height: 1.1;
  font-family: "Titillium Web", sans-serif;
}

h2 {
  font-size: 60px;
}

h3 {
  font-size: 40px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0;
}

address {
  margin: 0;
}

code,
kbd,
tt,
var {
  font-size: 16px;
}

abbr,
acronym {
  border-bottom: 1px dotted;
  cursor: help;
}

mark,
ins {
  text-decoration: none;
}

/*--------------------------------------------------------------
  ##  Elements
  --------------------------------------------------------------*/

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

hr {
  border: 0;
  height: 1px;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

li > ul,
li > ol {
  list-style: none;
}

img {
  height: auto;
  max-width: 100%;
}

figure {
  margin: 0;
}

table {
  margin: 0;
  width: 100%;
}

a {
  padding: 0;
  margin: 0;
}

.custom-container {
  width: 95%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1230px;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .container {
    max-width: 1140px;
  }
}

/*--------------------------------------------------------------
  ##  Forms
  --------------------------------------------------------------*/
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  border: 0px solid;
  border-radius: 0px;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  border-radius: 0px;
  padding: 0px;
}

textarea {
  width: 100%;
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
.logo {
  float: right;
  margin-left: 20px;
  width: 160px;
}

.fixed .logo {
  margin-top: 10px;
  float: right;
  width: 115px;
  margin-right: 55px;
  margin-bottom: 9px;
}

.mainmenu > ul {
  margin: 0;
  padding-top: 5px;
}

.mainmenu > ul li {
  display: inline-block;
  position: relative;
}

.mainmenu > ul li a {
  font-weight: 700;
  color: #000;
  padding: 6px 12px;
  display: inline-block;
  font-size: 13px;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 2.6px;
  position: relative;
  text-decoration: none;
}

.mainmenu > ul > li > a::after {
  content: "";
  position: absolute;
  left: 12px;
  top: 100%;
  width: 0;
  height: 1px;
  background: #6a6969;
  transition: all 0.2s;
}

.mainmenu > ul li a:hover:after,
.mainmenu > ul li a.active:after {
  width: 47%;
  transition: all 0.5s;
}

.mainmenu li.has-child > a {
  position: relative;
}

/*--------------------------------------------------------------
    Dropdown menu
    --------------------------------------------------------------*/
.sub-menu {
  position: absolute;
  z-index: 99999;
  background-color: #fff;
  width: 255px;
  box-shadow: 0px 10px 16px 0px rgba(36, 31, 31, 0.31);
  -webkit-box-shadow: 0px 10px 16px 0px rgba(36, 31, 31, 0.31);
  top: 115%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  padding: 20px 0;
  right: 0;
  border: 1px solid #eeeeee;
  border-radius: 2px;
}

.sub-menu li {
  position: relative;
  text-align: left;
}

.mainmenu ul li .sub-menu li a {
  padding: 8px 35px;
  width: 100%;
  display: block;
  color: #9b9b9b;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 700;
  letter-spacing: 0;
  padding-right: 0;
  position: relative;
  transition: all 0.3s;
}

.mainmenu ul li .sub-menu li a:hover {
  color: #000;
  transition: all 0.3s;
}

.mainmenu ul li .sub-menu li a:hover:before {
  background: #000;
  transition: all 0.3s;
}

.mainmenu ul li .sub-menu li a::before {
  position: absolute;
  content: "";
  left: 26px;
  top: 50%;
  width: 4px;
  height: 4px;
  background: #9b9b9b;
  border-radius: 100%;
  margin-top: -2px;
  transition: all 0.3s;
}

.mainmenu ul li .sub-menu li.has-child > a::after {
  position: absolute;
  content: "";
  right: 26px;
  top: 50%;
  width: 20px;
  height: 1px;
  background: #000;
  border-radius: 100%;
  margin-top: 1px;
  transition: all 0.6s;
}

.mainmenu li:hover .sub-menu {
  visibility: visible;
  top: 103%;
  opacity: 1;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.mainmenu li:hover .sub-menu .sub-menu {
  position: absolute;
  top: 0;
  right: 100%;
  opacity: 0;
  visibility: hidden;
}

.mainmenu li:hover .sub-menu li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
}

.mainmenu ul li .sub-menu li {
  width: 100%;
  position: relative;
}

.mainmenu ul li .sub-menu li:after {
  content: "";
  position: absolute;
  left: 2px;
  width: 6px;
  height: 28px;
  background: #efefef;
  top: 7px;
  transition: all 0.3s;
  opacity: 0;
}

.mainmenu ul li .sub-menu li:hover:after {
  height: 28px;
  transition: all 0.5s;
  opacity: 1;
}

.Rounded_Rectangle_3 {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(151, 151, 151);
  background-color: rgb(255, 255, 255);
  opacity: 0.16;

  position: absolute;
  left: 1154px;
  top: 101px;
  width: 253px;
  height: 246px;
  z-index: 81;
}

/*--------------------------------------------------------------
  ##  Navbar Three
  --------------------------------------------------------------*/

.header-area {
  padding-top: 26px;
  position: absolute;
  width: 100%;
  transition: all 0.3s;
  top: 0;
  z-index: 99;
}

.header-area.fixed {
  position: fixed;
  top: 0;
  padding-top: 0;
  transition: all 0.5s;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
}

.header-right-menu {
  padding: 7px 0px 0;
  width: 30px;
  margin-right: 18px;
}

.header-right-menu > ul > li {
  float: right;
  display: inline;
}

.mainmenu {
  text-align: center;
  /*    visibility: hidden;
    opacity: 0;*/
  -o-transition: opacity 0.3s ease 0.5s;
  -webkit-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.mainmenu.active {
  visibility: visible;
  opacity: 1;
}

.mainmenu > ul > li:nth-child(7) {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.mainmenu > ul > li:nth-child(6) {
  -webkit-transition-delay: 0.35s;
  -o-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.mainmenu > ul > li:nth-child(5) {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.mainmenu > ul > li:nth-child(4) {
  -webkit-transition-delay: 0.45s;
  -o-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.mainmenu > ul > li:nth-child(3) {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.mainmenu > ul > li:nth-child(2) {
  -webkit-transition-delay: 0.55s;
  -o-transition-delay: 0.55s;
  transition-delay: 0.55s;
}

.mainmenu > ul > li:nth-child(1) {
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.flaticon-tool {
  display: block;
}

.active .flaticon-tool {
  display: none;
}

.menu-btn .fa-bars {
  display: block;
  font-size: 21px;
}

.menu-btn .active .fa-bars {
  display: none;
}

.menu-btn .fa-times {
  display: none;
}

.menu-btn .active .fa-times {
  display: block;
  font-size: 21px;
}

.menu-btn img {
  width: 100px;
}
svg#Layer_1 {
  width: 30px;
  height: 30px;
}
button.menu-btn {
  padding: 0;
  background: transparent;
}

/*----------------------------------------*/
/*  ##   Offcanvus
/*----------------------------------------*/

.offcanvus-menu {
  width: 400px;
  background: #000;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 999999;
  padding-left: 50px;
  padding-top: 130px;
  right: -405px;
  transition: all 0.5s;
}

.close-offcanvus .svg {
  width: 14px;
  float: right;
  /* position: absolute; */
  height: 17px;
  margin-left: 5px;
  margin-right: 6px;
  overflow: hidden;
  margin-top: 5px;
}

.open.offcanvus-menu {
  right: 0;
  transition: all 0.5s;
  transition-delay: 0.3s;
}

.offcanvus-menu ul {
  transition: all 1s;
}

.menu-open .offcanvus-menu ul {
  padding-left: 0;
  transition: all 0.5s;
  transition-delay: 0.3s;
}

.close-offcanvus {
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  background: #000;
  padding: 5px 10px;
  cursor: pointer;
}

.close-offcanvus i {
  float: right;
  /* display: inline-block; */
  margin-right: 10px;
  margin-top: 6px;
  font-size: 14px;
}

.close-text {
  float: right;
  text-transform: uppercase;
  font-size: 13px;
}

.offcanvus-menu ul li {
  margin-bottom: 20px;
}

.offcanvus-menu > ul li a {
  font-size: 20px;
  color: #c2bdbd;
  text-transform: capitalize;
  position: relative;
  text-decoration: none;
}

.offcanvus-menu > ul li a:hover {
  color: #fff;
  transition: all 0.3s;
}

.offcanvus-menu ul li a::after {
  content: "";
  position: absolute;
  left: -50px;
  top: 15px;
  width: 0;
  height: 1px;
  background: #fff;
  transition: all 0.3s ease-in-out;
}

.offcanvus-menu ul li a:hover:after {
  width: 35px;
  transition: all 0.3s ease-in-out;
}

.offcanvus-menu ul li a.active:after {
  width: 35px;
  transition: all 0.3s ease-in-out;
}

.offcanvus-menu .footer-social-light ul li a {
  color: #c2bdbd;
  display: block;
  font-size: 12px;
}

.offcanvus-menu .footer-social-light ul li {
  width: 32px;
  height: 32px;
  line-height: 31px;
}

.offcanvus-menu .footer-social-light {
  text-align: center;
  position: absolute;
  bottom: 60px;
}

.mask-overlay {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  background: #fff;
  height: 100%;
  z-index: 9999;
  opacity: 0;
  transition: all 1s;
}

.mask-overlay::before {
  content: "";
  position: absolute;
  right: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: all 1.5s;
}

.open.mask-overlay {
  transition-delay: 0.3s;
  opacity: 0.5;
  right: 0;
  transition: all 0.5s;
}

.open.mask-overlay:before {
  opacity: 0.6;
  right: 0;
  transition: all 1s;
}

/*----------------------------------------*/
/*  #  Portfolio  section
/*----------------------------------------*/

.portfolio-area h3 span {
  color: #f54545;
  text-decoration: underline;
}

.portfolio-content {
  padding-top: 20px;
}

.single-gallery {
  z-index: 1;
  padding: 15px 0;
  display: inline-block;
  width: 100%;
}

.single-inner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;
  background: #fff;
  top: 0;
  right: 0;
  opacity: 0;
  transition: all 0.3s;
}

.single-gallery {
  cursor: url("../images/icon/cursor_h.png") 20 20, crosshair;
}

.single-portfolio-2 {
  cursor: url("../images/icon/cursor_r.png") 20 20, crosshair;
}

.single-gallery:hover .single-inner:before {
  opacity: 0.9;
  transition: all 0.3s;
}

.single-gallery:hover img {
  transform: scale(1.8);
  transition: all 0.9s;
}

.single-inner {
  display: block;
  overflow: hidden;
  position: relative;
}

.single-gallery-width2 {
  width: 66%;
}

.single-gallery img {
  width: 100%;
  position: relative;
  transition: all 0.3s;
}

.portfolio-item-wrapper {
  padding-top: 50px;
  margin-left: -15px;
  margin-right: -15px;
}

.portfolio-item-wrapper.style-five {
  margin-right: -5px;
  margin-left: -5px;
  padding-top: 55px;
}

.shape-one {
  width: 25%;
  height: auto;
}

.shape-two {
  width: 50%;
  height: auto;
}

.shape-three {
  width: 25%;
  height: auto;
}

.shape-four {
  width: 50%;
  height: auto;
}

.portfolio-filter-btn {
  text-align: left;
  margin-top: 60px;
  margin-left: 200px;
  display: inline-block;
  position: relative;
  z-index: 999;
}

.filter {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  text-align: center;
  padding: 4px 12px;
  position: relative;
  /* letter-spacing: 2.6px; */
  cursor: pointer;
}

.filter::after {
  content: "";
  position: absolute;
  left: 12px;
  top: 90%;
  width: 0;
  height: 1px;
  background: #6a6969;
  transition: all 0.2s;
}

.filter.react-tabs__tab--selected:after {
  width: 47%;
  transition: all 0.5s;
}

.filter:hover:after {
  width: 47%;
  transition: all 0.5s;
}

.filter.active:after {
  width: 47%;
  transition: all 0.5s;
}

.portfolio-section {
  padding-bottom: 50px;
  padding-top: 34px;
  position: relative;
}

.portfolio-hover {
  color: #000;
  position: absolute;
  bottom: 40px;
  left: 40px;
  z-index: 6;
}

.portfolio-hover h3 {
  font-size: 20px;
  transform: translateX(46px);
  transition: all 0.4s;
  opacity: 0;
  text-transform: capitalize;
}

.single-gallery:hover .portfolio-hover h3 {
  transform: translateX(0);
  transition: all 0.6s;
  opacity: 1;
}

.portfolio-hover h6 {
  font-size: 13px;
  letter-spacing: 2.6px;
  transform: translateX(-20px);
  transition: all 0.4s;
  opacity: 0;
  text-transform: uppercase;
}

.portfolio-hover .svg {
  transform: translateX(-20px);
  transition: all 0.4s;
  opacity: 0;
  width: 30px;
  z-index: 999;
  height: 26px;
}

.single-gallery:hover .portfolio-hover .svg {
  transform: translateX(0);
  transition: all 0.6s;
  opacity: 1;
}

.single-gallery:hover .portfolio-hover h6 {
  transform: translateX(0);
  transition: all 0.5s;
  opacity: 1;
}

/*----------------------------------------*/
/*  #  Footer  section
/*----------------------------------------*/

.contact-area {
  padding-bottom: 130px;
  background: #fff;
  position: relative;
  z-index: 95;
  padding-top: 50px;
}

.section-heading {
  text-align: center;
  color: #000000;
  margin-bottom: 50px;
  width: 100%;
}

.section-heading h3 {
  font-size: 40px;
  letter-spacing: 8px;
}

.section-heading p {
  font-size: 22px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  letter-spacing: 4.4px;
}

.contact-form {
  text-align: center;
}

.contact-form span {
  font-size: 25px;
  font-weight: 300;
  color: #8d8d8d;
  text-align: right;
  display: block;
  margin-bottom: 30px;
  line-height: 35px;
}

.contact-form select {
  text-align: left;
  float: right;
  width: 300px;
  border: none;
  border-bottom: 1px solid #ccc;
  color: #c4c4c4;
  appearance: none;
  appearance: none;

  background-repeat: no-repeat;
  background-position: 98% 12px;
}

.contact-form select:focus {
  border-bottom: 1px solid #929292;
  color: #9b9b9b;
}

.contact-form select > option {
  color: #aaa;
}

.contact-form .submit-wrapper {
  text-align: center;
}

.contact-form .submit-wrapper input {
  float: none;
}

.contact-form input {
  width: 470px;
  border: none;
  border-bottom: 1px solid #ccc;
  color: #9b9b9b;
  font-size: 16px;
  margin-bottom: 0;
  float: right;
  line-height: 34px;
}

.contact-form .submit {
  border: 2px solid #000;
  display: inline-block;
  padding: 12px 20px 14px;
  font-size: 22px;
  color: #000;
  text-transform: uppercase;
  margin: 0 auto;
  text-align: center;
  margin-top: 55px;
  font-weight: 700;
  letter-spacing: 4.4px;
  cursor: pointer;
  line-height: 20px;
  transition: all 0.5s;
  background: #fff;
  width: 470px;
}

.contact-form .submit:hover {
  background: #000;
  color: #fff;
  transition: all 0.5s;
}

.contact-form .underline,
.contact-form .underline {
  background: #000;
  border: 0;
  bottom: 30px;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  transition: width 0.3s;
  width: 0;
  z-index: 11;
}

.contact-form input:focus + .underline,
.contact-form textarea:focus + .underline {
  width: 470px;
  transition: width 0.3s;
}

.footer-area {
  padding-top: 65px;
  text-align: center;
  padding-bottom: 55px;
  background: #f8f8f8;
  margin-top: -2px;
}

.footer-social {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.footer-social ul {
  margin: 0;
}

.footer-social ul li {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #000;
  margin: 0 3px;
  overflow: hidden;
}

.footer-social ul li a {
  color: #000;
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-size: 14px;
}

@keyframes aaa {
  0% {
    left: -110%;
    top: 90%;
    width: 158%;
  }

  50% {
    left: -22%;
    top: -50%;
    width: 170%;
  }

  100% {
    top: -20%;
    left: -29%;
    width: 158%;
  }
}

.footer-social ul li a::before {
  content: "";
  position: absolute;
  width: 158%;
  height: 148%;
  background: #000;
  transform: rotate(45deg);
  left: -126%;
  top: 90%;
  z-index: -1;
}

.footer-social ul li a:hover::before {
  animation: aaa 0.7s 1;
  top: -20%;
  left: -29%;
}

.footer-social ul li a:hover {
  color: #fff;
  transition: all 0.3s;
}

.copyright {
  font-size: 10px;
  color: #616161;
  letter-spacing: 2px;
  margin: 0;
}

.copyright a {
  color: #000;
  letter-spacing: 2px;
  transition: color 0.3s;
}

.copyright a:hover {
  color: #ff5b5b;
  transition: color 0.3s;
}

.copyright i {
  color: #ff5b5b;
}

/*----------------------------------------*/
/*  #  Home page 2
/*----------------------------------------*/

.portfolio-two-wrapper {
  padding-bottom: calc(2.5% + 10px);
  background: #fff;
  position: relative;
  z-index: 1;
  padding-top: 50px;
}

.single-portfolio-2 img {
  width: 100%;
  transition: all 0.6s;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.single-portfolio-2:hover img {
  transform: scale(1.3);
  transition: all 0.6s;
  -webkit-filter: grayscale(0);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(0);
}

.single-portfolio-2 {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 10px 0;
  display: inline-block;
}

.portfolio-item-wrapper.style-two {
  margin-left: -10px;
  margin-right: -10px;
}

.single-portfolio-2 .single-inner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 96px;
  z-index: 0;
  background: #fff;
  top: auto;
  right: 0;
  opacity: 0;
  transition: all 0.3s;
  bottom: 0;
  z-index: 1;
  transition: all 0.5s;
}

.single-portfolio-2:hover .single-inner::before {
  opacity: 0.9;
  transition: all 0.5s;
}

.single-portfolio-2 .portfolio-hover-2 {
  color: #000;
  position: absolute;
  bottom: 19px;
  left: 27px;
  z-index: 2;
}

.single-portfolio-2 .portfolio-hover-2 h3 {
  font-size: 19px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 5px;
  letter-spacing: 9px;
  opacity: 0;
  transition: all 0.4s;
  text-transform: capitalize;
}

.single-portfolio-2:hover .portfolio-hover-2 h3 {
  letter-spacing: 2px;
  opacity: 1;
  transition: all 0.6s;
}

.single-portfolio-2 .portfolio-hover-2 h6 {
  font-size: 11px;
  letter-spacing: 2.6px;
  font-family: "Open Sans", sans-serif;
  transform: translateX(70px);
  transition: all 0.4s;
  opacity: 0;
  text-transform: uppercase;
}

.single-portfolio-2:hover .portfolio-hover-2 h6 {
  transform: translateX(0);
  transition: all 0.5s;
  opacity: 1;
}

.footer-two {
  background: #1d1d1d;
  padding: 60px 0 80px;
}

.footer-logo {
  text-align: center;
}

.footer-logo a {
  display: block;
}

.footer-logo a span {
  display: block;
  font-size: 32px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin-top: 20px;
}

.footer-social-light {
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
}

.footer-social-light ul {
  margin: 0;
}

.footer-social-light ul li {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #555555;
  margin: 0 3px;
  overflow: hidden;
}

.footer-social-light ul li a {
  color: #555555;
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.footer-social-light ul li a i {
  position: relative;
  z-index: 5;
}

.footer-social-light ul li:hover {
  border: 1px solid #fff;
  transition: all 0.15s;
}

@keyframes aaa {
  0% {
    left: -110%;
    top: 90%;
    width: 158%;
  }

  50% {
    left: -22%;
    top: -50%;
    width: 170%;
  }

  100% {
    top: -20%;
    left: -29%;
    width: 158%;
  }
}

.footer-social-light ul li a::before {
  content: "";
  position: absolute;
  width: 158%;
  height: 148%;
  background: #fff;
  transform: rotate(45deg);
  left: -126%;
  top: 90%;
  z-index: -1;
}

.footer-social-light ul li a:hover::before {
  animation: aaa 0.7s 1;
  top: -20%;
  left: -29%;
}

.footer-social-light ul li a:hover {
  color: #000;
  transition: all 0.3s;
}

.footer-inner {
  padding-top: 50px;
}

.footer-widget {
  text-align: center;
}

.widget-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #555;
  color: #555;
  margin-bottom: 46px;
}

.footer-widget p {
  font-size: 13px;
  color: #a29f9f;
  letter-spacing: 2.6px;
  line-height: 22px;
  margin: 0;
  font-weight: 600;
}

.footer-widget a {
  font-size: 13px;
  color: #ff5b5b;
  letter-spacing: 2.6px;
}

.footer-bottom {
  text-align: center;
}

.copyright.light a {
  color: #878787;
}

.footer-line {
  font-size: 13px;
  color: #878787;
  text-transform: uppercase;
  position: relative;
  font-weight: 700;
  letter-spacing: 2.6px;
  padding-bottom: 60px;
  display: inline-block;
}

.footer-line:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 36px;
  height: 1px;
  background: #484848;
  transform: translateX(-50%);
}

.footer-bottom {
  text-align: center;
  padding-top: 60px;
}

/*----------------------------------------*/
/*  #  Home page 3
/*----------------------------------------*/

.portfolio-wrapper-2-item {
  padding-top: 110px;
  background: #fff;
  position: relative;
  z-index: 1;
  padding-bottom: calc(2.5% + 15px);
}

.portfolio-wrapper-2-item.dark-bg {
  background: #131313;
  padding-top: calc(2.5% + 15px);
}

.single-portfolio-3 {
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: -1px;
}

.grid-sizer {
  width: 50%;
}

.single-portfolio-3::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 0;
  background: #000;
  transition: all 0.4s;
  z-index: 1;
}

.single-portfolio-3:hover:before {
  opacity: 0.85;
  transition: all 0.4s;
}

.single-portfolio-3 .portfolio-hover-3 {
  position: absolute;
  color: #fff;
  text-align: center;
  width: 100%;
  top: 50%;
  transform: translateY(10%);
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
  opacity: 0;
  z-index: 999;
}

.single-portfolio-3:hover .portfolio-hover-3 {
  height: auto;
  transition: all 0.5s;
  top: 50%;
  opacity: 1;
  transform: translateY(-50%);
}

.single-portfolio-3 .portfolio-hover-3 h3 {
  font-size: 20px;
  font-family: "Titillium Web", sans-serif;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.single-portfolio-3 .portfolio-hover-3 p {
  font-size: 11px;
  margin-bottom: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.single-portfolio-3 .portfolio-hover-3 a {
  font-size: 11px;
  margin: 0 10px;
  text-transform: uppercase;
}

.single-portfolio-3 .portfolio-hover-3 .line {
  display: block;
  width: 0;
  height: 1px;
  background: transparent;
  opacity: 0.5;
  margin-bottom: 15px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  border-bottom: 1px solid #fff;
  transition: all 0.3s;
}

.single-portfolio-3 .svg {
  fill: #fff;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.single-portfolio-3:hover .portfolio-hover-3 .line {
  width: 250px;
  transition: all 0.3s;
  transition-delay: 0.2s;
}

.grid-sizer {
  height: 300px;
}

.single-portfolio-3:after {
  content: "";
  display: block;
  clear: both;
}

.single-portfolio-3 img {
  width: 100%;
  transform: scale(1.3);
}

.footer-three {
  padding: 60px 0;
  background: #1d1d1d;
  position: sticky;
  bottom: 0;
}

.footer-logo-left {
  float: right;
  margin-right: 25px;
}

.footer-bottom-left {
  display: flex;
  align-items: center;
}

.footer-social-three {
  text-align: right;
  width: 100%;
  padding-top: 5px;
}

.footer-social-three ul {
  margin: 0;
}

.footer-social-three ul li {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  transition: all 0.3s;
  overflow: hidden;
}

@keyframes social-three-animation {
  0% {
    left: -110%;
    top: 90%;
    width: 158%;
  }

  50% {
    left: -22%;
    top: -50%;
    width: 170%;
  }

  100% {
    top: -20%;
    left: -29%;
    width: 158%;
  }
}

.footer-social-three ul li:hover a {
  color: #000;
  transition: all 0.3s;
}

.footer-social-three ul li a {
  color: #555555;
  display: block;
  transition: all 0.3s;
  position: relative;
}

.footer-social-three ul li a::before {
  content: "";
  position: absolute;
  width: 158%;
  height: 148%;
  background: #fff;
  transform: rotate(45deg);
  left: -126%;
  top: 90%;
  z-index: -1;
}

.footer-social-three ul li a:hover::before {
  animation: social-three-animation 0.7s 1;
  top: -20%;
  left: -29%;
}

.jaralax-content {
  height: 760px;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
  -moz-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: end;
  align-items: flex-end;
}

.single-portfolio-3 .portfolio-hover-4 {
  position: absolute;
  color: #fff;
  text-align: center;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  transition: all 0.5s;
  opacity: 1;
  z-index: 1;
}

.single-portfolio-3:hover .portfolio-hover-4 {
  transition: all 0.5s;
  top: 50%;
  opacity: 1;
}

.single-portfolio-3 .portfolio-hover-4 h3 {
  font-size: 20px;
  font-family: "Titillium Web", sans-serif;
  transform: translateY(78px);
  line-height: 30px;
  transition: all 0.5s;
  opacity: 0;
}

.single-portfolio-3 .portfolio-hover-4 p {
  font-size: 11px;
  margin-bottom: 20px;
  letter-spacing: 2px;
  transform: translateY(70px);
  transition: all 0.5s;
  opacity: 0;
}

.single-portfolio-3:hover .portfolio-hover-4 h3 {
  transform: translateY(0);
  transition: all 0.4s;
  opacity: 1;
}

.single-portfolio-3:hover .portfolio-hover-4 p {
  transform: translateY(0px);
  transition: all 0.5s;
  opacity: 1;
}

.single-portfolio-3 .portfolio-hover-4 span {
  font-size: 11px;
  margin: 0 20px;
  text-transform: uppercase;
}

.single-portfolio-3 .portfolio-hover-4 span i {
  font-size: 17px;
  margin-right: 10px;
  transform: translateY(3px);
}

.single-portfolio-3 .portfolio-hover-4 .line {
  display: block;
  width: 0;
  height: 1px;
  background: transparent;
  opacity: 0.5;
  margin-bottom: 15px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  border-bottom: 1px solid #fff;
  transition: all 0.3s;
}

.single-portfolio-3:hover .portfolio-hover-4 .line {
  width: 250px;
  transition: all 0.3s;
  transition-delay: 0.2s;
}

.contact-area.dark-bg {
  background: #0f0f0f;
  color: #979797;
  padding-top: 90px;
  position: relative;
  z-index: 9;
  padding-bottom: 0;
}

.dark-bg .section-heading {
  color: #979797;
}

.dark-bg .section-heading h3 {
  font-weight: 400;
  color: #fff;
}

.dark-bg .section-heading p {
  color: #fff;
}

.dark-bg .contact-form input {
  border-bottom: 1px solid #4a4a4a;
  color: #fff;
  background: #0f0f0f;
}

.dark-bg .contact-form span {
  color: #656565;
}

.dark-bg .contact-form .submit {
  border: 1px solid #545454;
  width: 550px;
  color: #707070;
  background: #131313;
}

.dark-bg .contact-form .submit:hover {
  background: #0f0f0f;
  color: #fff;
  border: 1px solid #fff;
}

.dark-bg .contact-form .underline,
.contact-form .underline {
  background: #929292;
  border: 0;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  transition: width 0.3s;
  width: 0;
  z-index: 11;
}

.dark-bg .form-messege {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -65px;
  background: #fff;
  color: #000;
  padding: 7px 25px;
  border-radius: 50px;
  visibility: hidden;
  opacity: 0;
}

.form-messege {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 35px;
  background: #000;
  color: #fff;
  padding: 7px 25px;
  border-radius: 50px;
  visibility: hidden;
  opacity: 0;
}

.form-messege.success,
.dark-bg .form-messege.success,
.form-messege.error,
.dark-bg .form-messege.error {
  opacity: 1;
  visibility: visible;
}

.dark-bg .contact-form select {
  border-bottom: 1px solid #4a4a4a;
  color: #999797;
}

.dark-bg .contact-form select:focus {
  color: #979797;
  border-bottom: 1px solid;
}

.footer-area.dark-bg {
  background: #0f0f0f;
}

.footer-two-light {
  background: #f1f1f1;
  padding: 80px 0 80px;
  position: sticky;
  bottom: 1px;
  z-index: 0;
}

.footer-widget {
  text-align: center;
}

.footer-two-light .widget-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #000001;
  color: #000;
  margin-bottom: 26px;
  font-size: 12px;
}

.footer-two-light .footer-widget p {
  font-size: 13px;
  color: #6a6969;
  letter-spacing: 2.6px;
  line-height: 22px;
  margin: 0;
}

.footer-two-light .footer-widget > a {
  font-size: 13px;
  color: #ff5b5b;
  letter-spacing: 2.6px;
  font-weight: 600;
  text-decoration: underline;
}

.footer-two-light .footer-line {
  color: #000;
}

/*----------------------------------------*/
/*  # Portfolio details page
/*----------------------------------------*/

.inner-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.prev-button .svg {
  float: right;
  margin-right: 20px;
  transform: rotate(180deg);
  transition: all 0.5s;
}

.prev-button:hover .svg {
  transform: translateX(-10px) rotate(180deg);
  transition: all 0.5s;
}

.next-button .svg {
  float: right;
  margin-left: 20px;
  transition: all 0.5s;
}

.next-button:hover .svg {
  transform: translateX(10px);
  transition: all 0.5s;
}

.inner-box span {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
}

.prev-button a,
.next-button a {
  background: #d5d5d5;
  padding: 40px 30px;
  line-height: 20px;
  display: block;
  transition: all 0.3s;
}

.inner-box .next-button a:hover,
.inner-box .prev-button a:hover {
  background: #fff;
  transition: all 0.3s;
}

.details-footer .inner-box .next-button a:hover,
.details-footer .inner-box .prev-button a:hover {
  background: #f3f4ff;
  transition: all 0.3s;
  color: #7882ff;
}

.portfolio-intro {
  padding: 0 165px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}

.portfolio-intro {
  padding: 0 165px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #fff;
}

.portfolio-intro p {
  margin: 0;
  font-weight: 600;
  letter-spacing: 1px;
}

.portfolio-intro p a {
  color: #7882ff;
}

.portfolio-detail-intro .link {
  border: 1px solid #000;
  padding: 14px;
  padding-right: 44px;
  padding-left: 26px;
  color: #7882ff;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  transition: all 0.5s;
  display: inline-block;
}

.portfolio-detail-intro .link .svg {
  margin-top: 0;
  line-height: 36px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 11px;
  transform: rotate(180deg);
}

/* .portfolio-detail-intro .link:hover {
  transition: all 0.5s;
  padding-right: 20px;
  padding-left: 50px;
} */

.portfolio-details .link i {
  color: #7882ff;
  margin-right: 4px;
  transition: all 0.5s;
}

.portfolio-details .link:hover i {
  transform: translateX(90px);
  transition: all 0.5s;
}

.portfolio-detail-intro > span {
  font-size: 16px;
  color: #000;
  font-weight: 700;
  margin-bottom: 45px;
  display: inline-block;
  position: relative;
}

.portfolio-detail-intro .sub-heading:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -12px;
  background: #e1e1e1;
  width: 30px;
  height: 3px;
}

.portfolio-detail-intro h3 {
  font-size: 22px;
  font-weight: 700;
  color: #000;
  margin-bottom: 40px;
}

.portfolio-detail-intro p {
  font-size: 16px;
  color: #575757;
  margin-bottom: 50px;
}

.portfolio-detail-intro {
  border: 1px solid #000;
  padding: 60px 70px;
}

.portfolio-details .main-image img {
  width: 100%;
}

.portfolio-details {
  padding-top: 80px;
  padding-bottom: 120px;
}

.slider-area {
  padding-bottom: 45px;
}

.slider-area .item {
  padding: 0 10px;
}
.slick-next:before {
  content: "NEXT" !important;
}
.slick-prev {
  left: 0 !important;
}

.slick-prev:before {
  content: "PREV" !important;
}
.slick-prev:before,
.slick-next:before {
  color: #000 !important;
  font-family: "Titillium Web", sans-serif !important;
  font-size: 15px !important;
}
.slick-next {
  right: 0 !important;
}
.slick-prev,
.slick-next {
  top: -28px !important;
  width: 35px !important;
}

.section-heading-2 {
  font-size: 36px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 900;
  color: #000;
  letter-spacing: 4.32px;
  position: relative;
}

.section-heading-2:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -12px;
  height: 3px;
  width: 27px;
  background: #e1e1e1;
}

.slider-one {
  padding-top: 65px;
}

.details-inner {
  padding-bottom: 105px;
}

.details-inner h6 {
  font-size: 22px;
  color: #7c7d92;
  margin-top: 40px;
  margin-bottom: 14px;
}

.details-inner h6 span {
  color: #7882ff;
}

.details-inner p {
  font-size: 16px;
  color: #575757;
  letter-spacing: 0.4px;
  line-height: 1.88;
}

.details-inner-two {
  padding: 120px 0 105px;
}

.details-inner-two .sub-heading {
  font-size: 16px;
  color: #7882ff;
  position: relative;
  display: inline-block;
  margin-bottom: 47px;
}

.details-inner-two .sub-heading:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -12px;
  height: 3px;
  width: 27px;
  background: #e1e1e1;
}

.details-inner-two h6 {
  font-size: 22px;
  color: #000;
  margin-bottom: 42px;
}

.details-inner-two p {
  font-size: 16px;
  color: #575757;
  letter-spacing: 0.4px;
  line-height: 1.88;
}

.details-inner h6 .svg {
  width: 28px;
  margin-right: 8px;
  transform: translateY(8px);
  height: 28px;
}

.details-inner h6 .svg path {
  fill: #7882ff;
}

.gallery-details {
  padding-bottom: 100px;
}

.gallery-img {
  margin: 2px;
}

.gallery-img img {
  width: 100%;
}

.portfolio-intro span {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  text-align: center;
}

.details-footer {
  padding-bottom: 100px;
  border-bottom: 1px solid #f4f4f4;
  position: relative;
  z-index: 1;
  background: #fff;
}

.video-area {
  min-height: 590px;

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-inner {
  width: 400px;
  margin: 0 auto;
}

.video-area-wrapper {
  text-align: center;
}

.video-area-wrapper h5 {
  font-size: 60px;
  color: #000;
  line-height: 48px;
  letter-spacing: 7.2px;
  font-weight: 900;
  margin-bottom: 40px;
}

.video-inner h5 {
  font-size: 60px;
  color: #000;
  line-height: 48px;
  letter-spacing: 7.2px;
  font-weight: 900;
  margin-bottom: 40px;
}

.video-inner span {
  display: block;
  width: 100%;
  font-size: 22px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 1.1px;
}

.video-inner a {
  color: #000;
  font-size: 35px;
  padding: 15px 34px;
  border: 4px solid rgba(0, 0, 0, 0.8);
  display: inline-block;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

@keyframes bbb {
  0% {
    left: -110%;
    top: 90%;
  }

  50% {
    left: -22%;
    top: -70%;
  }

  100% {
    top: -20%;
    left: -29%;
  }
}

.video-inner a::before {
  content: "";
  position: absolute;
  width: 195%;
  height: 205%;
  background: #000;
  transform: rotate(45deg);
  left: -126%;
  top: 106%;
  z-index: -1;
}

.video-inner a:hover::before {
  animation: bbb 0.7s 1;
  top: -20%;
  left: -29%;
}

.video-inner a:hover {
  border: 4px solid rgba(0, 0, 0, 1);
  transition: all 0.5s;
}

.video-inner a:hover {
  color: #fff;
  transition: all 0.8s;
}

.details-footer .prev-button a,
.details-footer .next-button a {
  background: #f1f1f1;
}

.slider-two.owl-carousel .owl-nav {
  position: absolute;
  top: -62px;
  float: right;
  text-align: right;
  right: 0;
}

.slider-two .owl-prev .svg {
  transform: rotate(180deg);
  margin-right: 20px;
}

.slider-two .owl-next .svg {
  margin-left: 20px;
}

.slider-two.owl-carousel .owl-nav button.owl-next,
.slider-two.owl-carousel .owl-nav button.owl-prev {
  font-size: 11px;
}

.slider-two .owl-prev {
  margin-right: 40px;
  position: relative;
}

.slider-two .owl-prev::after {
  content: "";
  position: absolute;
  right: -20px;
  top: 4px;
  width: 1px;
  height: 74%;
  background: #000;
}

.slider-two .owl-nav .svg {
  width: 25px;
}

.owl-carousel button.owl-dot {
  background: #ebebeb;
  color: inherit;
  /* border: none; */
  padding: 5px !important;
  font: inherit;
  margin: 4px;
  border-radius: 50px;
  border: 2px solid #bebebe;
}

.owl-carousel button.owl-dot.active {
  background: #d7d7d7;
  border: 2px solid #7a7a7a;
}

.slider-two .owl-dots {
  text-align: center;
  padding-top: 20px;
}

.slider-area-two {
  padding-bottom: 130px;
}

.slider-area-two p {
  font-size: 22px;
  color: #7c7d92;
  font-weight: 700;
  font-family: "Titillium Web", sans-serif;
  margin-top: 30px;
  margin-bottom: 35px;
}

.details-conclusion {
  padding: 130px 0;
}

.details-conclusion p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 1.88;
}

.details-conclusion .section-heading-2 {
  margin-bottom: 30px;
}

/*----------------------------------------*/
/*  # About page one
/*----------------------------------------*/

.about-area {
  padding: 80px 0 70px;
}

.breadcrumb-wrapper h1 {
  font-size: 60px;
  color: white;
  font-weight: 900;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.breadcrumb-wrapper {
  text-align: center;
  padding-top: 150px;
  background: url(../images/1692544121199.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  background-attachment: fixed;
}

.breadcrumb-wrapper.with-next-prev {
  text-align: center;
  padding-top: 300px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  background-attachment: fixed;
}

section {
  position: relative;
  z-index: 1;
  background: #fff;
}

.breadcrumb-box {
  display: flex;
  width: 100%;
  justify-content: center;
}

.breadcrumb-intro {
  padding: 38px 200px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #fff;
}

.breadcrumb-intro p {
  margin: 0;
  font-weight: 600;
  letter-spacing: 1px;
}

.breadcrumb-intro p a {
  color: #7882ff;
}

.about-social {
  text-align: left;
  width: 100%;
  margin-top: 70px;
}

.about-social ul {
  margin: 0;
}

.about-social ul li {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #000;
  margin: 0 3px;
}

.about-social ul li a {
  color: #000;
  display: block;
}

.about-dtails h6 {
  font-size: 22px;
  color: #000;
  line-height: 34px;
  margin-bottom: 25px;
}

.about-dtails p {
  font-size: 16px;
  color: #575757;
  line-height: 30px;
}

.about-dtails-wrapper {
  display: flex;
  align-content: center;
  align-items: center;
  padding: 0 70px;
  border: 1px solid #000;
  height: 100%;
}
.about-area.style-two {
  text-align: center;
}

.about-area.style-two .about-dtails-wrapper {
  display: block;
  height: auto;
  padding: 80px 50px;
  margin-top: 30px;
}

.about-area.style-two .about-social {
  text-align: center;
  width: 100%;
  margin-top: 90px;
}

/*----------------------------------------*/
/*  # Responsive
/*----------------------------------------*/

@media (min-width: 1200px) and (max-width: 1400px) {
  .video-bg-wrapper {
    height: 650px;
  }
}

@media (max-width: 1199px) {
  .footer-two-light {
    position: relative;
  }

  .about-dtails-wrapper {
    padding: 0;
    border: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .portfolio-hover {
    bottom: 30px;
    left: 30px;
  }

  .portfolio-section {
    padding-bottom: 0px;
  }

  .contact-form span {
    font-size: 21px;
  }

  .about-area.style-two .about-dtails-wrapper {
    padding: 40px 10px;
  }

  .banner-inner h1 {
    font-size: 175px;
    line-height: 165px;
  }

  .banner-inner {
    padding-top: 55px;
  }

  .animated-line ul {
    width: 900px;
    margin: 0 auto;
  }

  .portfolio-detail-intro {
    padding: 30px;
  }

  .video-bg-wrapper {
    height: 550px;
  }

  .jaralax-content {
    height: 590px;
  }

  .face-image {
    width: 75%;
  }

  .video-content-text h2 {
    font-size: 45px;
  }
}

@media (max-width: 991px) {
  .portfolio-content {
    padding-top: 50px;
  }

  .portfolio-item-wrapper {
    margin-left: -6px;
    margin-right: -6px;
  }

  .portfolio-item-wrapper.style-two {
    margin-right: -8px;
  }

  .portfolio-two-wrapper {
    padding-top: calc(2.5% + 12px);
    padding-bottom: calc(2.5% + 5px);
  }

  .portfolio-item-wrapper.style-five {
    margin-bottom: 5px;
  }

  .portfolio-wrapper-2-item {
    padding-top: calc(2.5% + 72px);
  }

  .portfolio-filter-btn {
    margin-top: 0px;
    margin-left: 200px;
    position: fixed;
  }

  .fixed .logo {
    width: 115px;
    margin: 0;
  }

  .logo {
    width: 115px;
    margin: 0;
  }

  .shape-one {
    width: 100%;
  }

  .header-area {
    padding-bottom: 10px;
    position: fixed;
    padding-top: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    background: white;
  }

  .header-area.fixed {
    margin-top: 0;
    padding-bottom: 10px;
    position: fixed;
    padding-top: 10px;
    background: #fff;
  }

  .mainmenu {
    display: none;
  }

  .portfolio-section {
    padding-top: 14px;
  }

  .header-right-menu {
    padding: 0;
  }

  .header-right-menu img {
    min-width: 35px;
  }

  .mobile-pos {
    position: inherit;
  }

  .offcanvus-menu {
    width: 300px;
    padding-top: 75px;
  }

  .filter {
    padding-left: 0;
    padding-bottom: 0;
  }

  .filter::after {
    left: 0;
    top: 92%;
  }

  .contact-form span {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 13px;
  }

  .section-heading h3 {
    font-size: 35px;
  }

  .section-heading p {
    font-size: 17px;
  }

  .contact-area {
    padding-top: 0;
    padding-bottom: 80px;
  }

  .contact-form input {
    font-size: 14px;
    margin-bottom: 25px;
  }

  .dark-bg .contact-form .underline,
  .contact-form .underline {
    bottom: 12px;
  }

  .contact-form select {
    margin-bottom: 20px;
  }

  .portfolio-section-six .portfolio-item-wrapper {
    padding-top: 80px;
  }

  .footer-two {
    position: relative;
  }

  .map {
    padding-top: 60px;
  }
  .gmap3-area {
    height: 300px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 95%;
  }

  .single-gallery {
    margin-bottom: 0;
  }

  .contact-form span {
    text-align: right;
    font-size: 16px;
  }

  .dark-bg .contact-form input {
    width: 320px;
  }

  .dark-bg .contact-form input:focus + .underline,
  .dark-bg .contact-form textarea:focus + .underline {
    width: 320px;
  }

  .dark-bg .contact-form .submit {
    width: 420px;
  }

  .single-portfolio-width2 {
    width: 33.33%;
  }

  .banner-inner h1 {
    font-size: 148px;
    line-height: 122px;
  }

  .video-content-text h2 {
    font-size: 36px;
  }

  .banner-inner {
    padding-top: 51px;
  }
  .animated-line ul {
    width: 730px;
    margin: 0 auto;
  }

  .animated-banner {
    padding: 95px 0 22px;
  }

  .animated-line ul li {
    height: 3px;
    margin-bottom: 2px;
  }

  .footer-two-light {
    position: relative;
  }

  .footer-social ul li {
    width: 33px;
    height: 33px;
    line-height: 33px;
    margin: 0 1px;
  }

  .footer-social-light ul li {
    width: 33px;
    height: 33px;
    line-height: 33px;
    margin: 0 1px;
  }

  .footer-two .footer-logo img {
    width: 54px;
  }

  .footer-two .widget-icon {
    width: 33px;
    height: 33px;
    line-height: 33px;
    margin-bottom: 33px;
  }

  .footer-logo a span {
    font-size: 28px;
    margin-top: 10px;
  }

  .footer-social-light {
    margin-bottom: 25px;
  }

  .footer-widget .footer-social {
    margin-bottom: 25px;
  }

  .footer-logo img {
    width: 140px;
  }

  .footer-two-light .widget-icon {
    width: 33px;
    height: 33px;
    line-height: 33px;
    margin-bottom: 33px;
  }

  .footer-line {
    padding-bottom: 40px;
  }

  .footer-bottom {
    padding-top: 45px;
  }

  .contact-form input {
    width: 300px;
  }

  .contact-form input:focus + .underline,
  .contact-form textarea:focus + .underline {
    width: 300px;
  }
  .portfolio-details {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  .portfolio-detail-intro {
    padding: 50px 50px;
    margin-top: 25px;
  }

  .jaralax-content {
    height: 430px;
  }

  .face-image {
    height: 395px;
    width: 70%;
  }

  .video-bg-wrapper {
    height: 430px;
    margin-top: 52px;
  }

  .prev-button a,
  .next-button a {
    padding: 24px 30px;
  }
  .portfolio-intro {
    padding: 0px 26px;
  }
  .with-next-prev.breadcrumb-wrapper,
  .breadcrumb-wrapper {
    padding-top: 195px;
  }
  .details-inner-two {
    padding: 80px 0;
  }
  .details-inner-two .sub-heading {
    margin-bottom: 30px;
  }
  .details-inner-two h6 {
    margin-bottom: 30px;
  }
  .slider-one {
    padding-top: 50px;
  }

  .details-inner h6 {
    line-height: 35px;
  }

  .video-area {
    min-height: 400px;
  }

  .gallery-details {
    padding-bottom: 80px;
  }

  .details-footer {
    padding-bottom: 80px;
  }

  .details-inner {
    padding-bottom: 80px;
  }

  .slider-two.owl-carousel .owl-nav {
    bottom: -33px;
    right: 50%;
    transform: translate(50%);
    top: auto;
  }

  .details-conclusion {
    padding: 80px 0;
  }

  .about-area {
    padding: 80px 0 70px;
    text-align: center;
  }

  .about-area.style-two .about-dtails-wrapper {
    padding: 15px 50px;
  }

  .about-dtails h6 {
    margin-bottom: 25px;
    margin-top: 40px;
  }

  .about-social {
    margin-top: 40px;
    text-align: center;
  }

  .breadcrumb-intro {
    padding: 16px 100px;
  }

  .blog-posts > article .post-content .title {
    font-size: 25px;
  }

  .post-content p {
    font-size: 14px;
  }

  .description {
    font-size: 14px;
  }

  .single-post .contact-item {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .portfolio-item-wrapper {
    margin-left: -10px;
    margin-right: -10px;
  }

  .footer-bottom-left {
    justify-content: center;
    margin-bottom: 15px;
  }

  .footer-social-three {
    text-align: center;
  }

  .banner-inner h1 {
    font-size: 105px;
    line-height: 100px;
  }

  .banner-inner {
    padding-top: 51px;
  }
  .animated-line ul {
    width: 530px;
    margin: 0 auto;
  }

  .animated-banner {
    padding: 60px 0 22px;
  }

  .animated-line ul li {
    height: 2px;
    margin-bottom: 2px;
  }

  .prev-button a,
  .next-button a {
    padding: 24px 12px;
  }

  .portfolio-details {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  .portfolio-intro {
    padding: 0px 20px;
  }

  .portfolio-intro p {
    letter-spacing: 0;
  }

  .breadcrumb-wrapper,
  .with-next-prev.breadcrumb-wrapper {
    padding-top: 176px;
  }

  .portfolio-detail-intro {
    padding: 50px 50px;
    margin-top: 25px;
  }

  .details-inner-two {
    padding: 75px 0 60px;
  }

  .details-inner-two .sub-heading {
    margin-bottom: 30px;
  }

  .details-inner-two h6 {
    margin-bottom: 30px;
  }

  .slider-one {
    padding-top: 30px;
  }

  .details-inner {
    padding-bottom: 55px;
  }

  .video-inner h5 {
    font-size: 50px;
    line-height: 45px;
  }

  .video-inner a {
    font-size: 25px;
    padding: 12px 30px;
  }

  .details-inner h6 {
    line-height: 35px;
  }

  .video-area {
    min-height: 400px;
  }

  .gallery-details {
    padding-bottom: 80px;
  }

  .details-footer {
    padding-bottom: 80px;
  }

  .slider-area {
    padding-bottom: 30px;
  }

  .slider-one .owl-item.center > div {
    box-shadow: 0px 17px 30px 0 rgba(7, 0, 70, 0.22);
  }

  .next-button .svg {
    margin-left: 8px;
    height: 20px;
    margin-top: 3px;
  }

  .prev-button .svg {
    margin-right: 8px;
    height: 20px;
    margin-top: 3px;
  }

  .details-conclusion {
    padding: 80px 0;
  }

  .details-conclusion {
    padding: 80px 0;
  }

  .about-dtails h6 {
    margin-bottom: 25px;
    margin-top: 40px;
  }
  .about-social {
    margin-top: 40px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .about-dtails {
    padding-right: 0;
  }

  .about-area {
    padding: 80px 0 70px;
    text-align: center;
  }

  .portfolio-section {
    padding-top: 15px;
  }

  .portfolio-filter-btn {
    display: none;
  }

  .container {
    max-width: 100%;
  }

  .header-right-menu {
    right: 15px;
  }

  .portfolio-wrapper-2-item.dark-bg {
    padding: 15px 0;
  }

  .custom-container {
    width: 100%;
  }

  .grid-sizer,
  .single-portfolio-3 {
    width: 100%;
  }

  .offcanvus-menu .footer-social-light {
    bottom: 20px;
  }

  .portfolio-wrapper-2-item {
    padding-top: 73px;
  }

  .portfolio-two-wrapper {
    padding-top: 14px;
  }

  .animated-line ul li:nth-child(10),
  .animated-line ul li:nth-child(11),
  .animated-line ul li:nth-child(12),
  .animated-line ul li:nth-child(13),
  .animated-line ul li:nth-child(14),
  .animated-line ul li:nth-child(15),
  .animated-line ul li:nth-child(16) {
    margin-left: 10px;
  }

  .animated-line ul li:nth-child(39),
  .animated-line ul li:nth-child(40),
  .animated-line ul li:nth-child(41),
  .animated-line ul li:nth-child(42),
  .animated-line ul li:nth-child(43),
  .animated-line ul li:nth-child(44),
  .animated-line ul li:nth-child(45) {
    margin-left: -10px;
  }

  .footer-line::after {
    display: none;
  }

  .footer-two-light {
    position: relative;
  }

  .footer-widget .footer-social,
  .footer-widget .footer-social-light {
    margin-top: 70px;
    margin-bottom: 12px;
  }

  .footer-two-light .widget-icon {
    margin-bottom: 20px;
  }

  .footer-two-light .widget-icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .footer-social ul li {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .video-content-text h2 {
    font-size: 25px;
  }

  .video-bg-wrapper {
    height: 300px;
    margin-top: 55px;
  }

  .face-image {
    height: 285px;
    width: 80%;
  }

  .jaralax-content {
    height: 290px;
  }

  .slider-two.owl-carousel .owl-nav {
    bottom: -33px;
    right: 50%;
    transform: translate(50%);
    top: auto;
  }

  .breadcrumb-intro {
    padding: 10px 33px;
  }

  .single-post .contact-item {
    width: 100%;
  }

  .sidebar {
    padding: 64px 0 0 0;
  }

  .single-post {
    padding: 50px 0;
  }

  .blog {
    padding: 50px 0;
  }

  .about-area.style-two .about-dtails {
    padding-right: 0;
    width: 100%;
    padding-top: 0px;
  }
  .breadcrumb-wrapper h1 {
    font-size: 40px;
  }
  .breadcrumb-wrapper.with-next-prev,
  .breadcrumb-wrapper {
    background-attachment: inherit;
  }

  .portfolio-section-six {
    padding-bottom: 15px;
  }

  .portfolio-two-wrapper {
    padding-bottom: 15px;
  }

  .portfolio-section-six .portfolio-item-wrapper {
    padding-top: 32px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .dark-bg .contact-form .submit {
    width: 60%;
    margin-top: 25px;
  }
  .contact-form .submit {
    width: 60%;
    margin-top: 25px;
  }

  .contact-form input {
    width: 100%;
  }

  .contact-form input:focus + .underline,
  .contact-form textarea:focus + .underline {
    width: auto;
    right: 15px;
    left: 15px;
  }

  .portfolio-section {
    padding-top: 50px;
  }

  .portfolio-content {
    padding-top: 24px;
  }

  .single-portfolio-2 {
    width: 100%;
  }

  .single-gallery {
    padding: 0;
    padding-bottom: 15px;
  }

  .portfolio-item-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  .single-inner {
    width: 100%;
  }

  .single-portfolio-3 .portfolio-hover-3 h3 {
    font-size: 18px;
  }

  .single-portfolio-3 .portfolio-hover-3 p {
    font-size: 10px;
    margin-bottom: 8px;
    letter-spacing: 0.5px;
  }

  .single-portfolio-3 .portfolio-hover-3 .line {
    margin-bottom: 10px;
  }

  .single-portfolio-3:hover .portfolio-hover-3 .line {
    width: 110px;
  }

  .footer-bottom-left {
    display: inline-block;
    align-items: center;
    width: 100%;
  }

  .footer-logo-left {
    float: none;
    margin-right: 0;
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 15px;
  }

  .footer-social-three {
    text-align: center;
  }

  .footer-social-three ul li {
    width: 33px;
    height: 33px;
    line-height: 33px;
  }

  .single-portfolio-width2 {
    width: 100%;
  }

  .banner-inner h1 {
    font-size: 60px;
    line-height: 50px;
  }

  .banner-inner {
    padding-top: 42px;
  }
  .animated-line {
    top: 30px;
  }
  .animated-line ul {
    width: 530px;
    margin: 0 auto;
  }

  .animated-banner {
    padding: 60px 0 22px;
  }
  .animated-line ul li {
    height: 2px;
    margin-bottom: 1px;
  }

  .animated-line ul li:nth-child(10),
  .animated-line ul li:nth-child(11),
  .animated-line ul li:nth-child(12),
  .animated-line ul li:nth-child(13),
  .animated-line ul li:nth-child(14),
  .animated-line ul li:nth-child(15),
  .animated-line ul li:nth-child(16) {
    margin-left: 0;
  }

  .prev-button a,
  .next-button a {
    padding: 24px 12px;
  }

  .portfolio-details {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .portfolio-intro {
    padding: 0px 20px;
  }

  .portfolio-intro p {
    letter-spacing: 0;
  }

  .breadcrumb-wrapper,
  .with-next-prev.breadcrumb-wrapper {
    padding-top: 100px;
  }

  .portfolio-detail-intro {
    padding: 20px 10px 20px 20px;
    margin-top: 25px;
  }

  .details-inner-two {
    padding: 50px 0;
  }

  .details-inner-two .sub-heading {
    margin-bottom: 30px;
  }

  .details-inner-two h6 {
    margin-bottom: 30px;
  }

  .slider-one {
    padding-top: 60px;
  }

  .details-inner {
    padding-bottom: 50px;
  }

  .video-inner h5 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 30px;
  }

  .portfolio-detail-intro p {
    font-size: 14px;
  }

  .video-inner a {
    font-size: 25px;
    padding: 12px 30px;
  }

  .details-inner h6 {
    line-height: 35px;
  }

  .video-area {
    min-height: 320px;
  }
  .gallery-details {
    padding-bottom: 5px;
  }
  .details-footer {
    padding-bottom: 50px;
  }

  .slider-area {
    padding-bottom: 50px;
  }

  .next-button .svg {
    margin-left: 8px;
    height: 20px;
    margin-top: 3px;
  }

  .prev-button .svg {
    margin-right: 8px;
    height: 20px;
    margin-top: 3px;
  }

  .inner-box {
    padding-top: 0;
  }

  .breadcrumb-wrapper h1 {
    font-size: 30px;
    margin-bottom: 30px;
  }

  .portfolio-detail-intro h3 {
    font-size: 18px;
  }

  .section-heading-2 {
    font-size: 22px;
    letter-spacing: 1.32px;
  }

  .slider-one.owl-carousel .owl-nav {
    top: 25px;
    right: 15px;
  }

  .video-area-wrapper h5 {
    font-size: 42px;
    margin-bottom: 22px;
  }
  .details-conclusion {
    padding: 50px 0;
  }

  .details-footer .portfolio-intro span {
    display: none;
  }

  .about-area {
    padding: 50px 0 80px;
    text-align: center;
  }

  .about-dtails h6 {
    margin-bottom: 25px;
    margin-top: 40px;
    font-size: 20px;
  }

  .about-social {
    margin-top: 40px;
    text-align: center;
  }

  .blog-posts > article .post-content .title {
    font-size: 25px;
  }

  .post-content p {
    color: #000;
    font-size: 14px;
  }

  .blog-posts > article .post-content {
    padding: 0 0 30px 0;
  }

  .blog-posts > article .post-content .post-share-wrap {
    position: relative;
    margin-top: 10px;
  }

  .single-post #post-author .avatar-author {
    width: auto;
    height: auto;
    overflow: hidden;
    padding-bottom: 0;
  }

  .single-post #post-author .post-author-content {
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
  }

  .single-post #comments .comment-context {
    margin-left: 40px;
    padding-left: 15px;
  }

  .single-post #comments .comment-content {
    letter-spacing: normal;
    font-size: 14px;
  }

  .single-post #comments .comment-avatar {
    width: 40px;
  }

  .video-bg-wrapper {
    height: 235px;
    margin-top: 55px;
  }

  .video-content-text h2 {
    font-size: 18px;
  }

  .footer-three {
    text-align: center;
  }

  .video-content-text {
    height: 206px;
  }

  .slick-slide img {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 420px) {
  .video-content-text h2 {
    font-size: 14px;
  }
  .video-bg-wrapper {
    height: 181px;
    margin-top: 55px;
  }
  .video-content-text {
    display: inline-block;
    padding-top: 40px;
  }

  .widget-likebox-content iframe {
    width: 290px;
  }
}
